import { graphql } from "gatsby"
import React from "react"
import WhatsOnFilter from "../components/WhatsOnFilter/WhatsOnFilter"
import Layout from "../components/layout/Layout/Layout"
import WhatsOn from "../components/WhatsOn/WhatsOn"
import GridContainer from "../components/layout/GridContainer/GridContainer"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"

export const query = graphql`
  query($startDate: Date!, $endDate: Date!) {
    datoCmsSiteConfig {
      whatsOnNoResultsText
    }
    whatsOn: allDatoCmsEvent(
      sort: { order: ASC, fields: eventDate }
      filter: { eventDate: { gte: $startDate, lt: $endDate } }
    ) {
      ...WhatsOn
    }
  }
`

const WhatsOnPage = props => {
  const getYear = month =>
    month.startDate.substring(0, month.startDate.indexOf("-"))

  const getPageTitle = context => {
    const monthData = context.months[context.monthIndex]
    let title = "What's On"

    if (context.monthName) {
      title = `${title}: ${context.monthName}`
    }

    if (!monthData.isCurrentYear) {
      title = `${title} ${getYear(monthData)}`
    }

    return title
  }

  return (
    <Layout
      metaTitle={"What's on in " + props.pageContext.monthName}
      metaDescription={
        "List of in Selsey during" + props.pageContext.monthName + "."
      }
      metaImage={null}
      twitterCard={null}
      pathname={props.uri}
    >
      <HeroBanner title={getPageTitle(props.pageContext)} />
      <GridContainer>
        <Breadcrumb url={props.uri} />
        <WhatsOnFilter
          activeMonth={{
            name: props.pageContext.monthName,
            index: props.pageContext.monthIndex,
          }}
          months={props.pageContext.months}
        />
        <WhatsOn
          events={props.data.whatsOn.nodes}
          message={
            props.data.datoCmsSiteConfig.whatsOnNoResultsText
              ? props.data.datoCmsSiteConfig.whatsOnNoResultsText
              : undefined
          }
        />
        {props.data.whatsOn.nodes.length > 0 && (
          <WhatsOnFilter
            activeMonth={{
              name: props.pageContext.monthName,
              index: props.pageContext.monthIndex,
            }}
            months={props.pageContext.months}
          />
        )}
      </GridContainer>
    </Layout>
  )
}

export default WhatsOnPage
